

$logo: "/static/logo_B.png";

@import "./support/support";
@import "./color_schemes/light";
@import "./color_schemes/tryalgo";
@import "./modules";
@import "./custom/custom";

div.opaque {
  background-color: $body-background-color;
}

